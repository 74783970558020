<div class="container">
  <main id="main">
    <div *ngIf="this.partido" class="row">
      <!-- botonera -->
      <div class="col-sm-12" *ngIf="this.estaAutorizado(AutorizacionPartidoEnum.Administrador) || this.estaAutorizado(AutorizacionPartidoEnum.CapitanLocal)">
        <div class="p-4" style="text-align: right;">
          <div class="dropdown" *ngIf="!this.estaFinalizado()">
            <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              Acciones
            </button>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" [routerLink]="['/partido/modificar', partido.id]"><i class="fa fa-edit"></i>
                  Modificar</a></li>
              <li><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#terminarPartidoModal"><i
                    class="fa fa-hourglass-end"></i> Finalizar</a></li>
              <li><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#eliminarPartidoModal"
                  *ngIf="!this.estaFinalizado()"><i class="fa fa-times"></i> Eliminar</a></li>
            </ul>
          </div>
        </div>
      </div>

      <!-- partido miniatura -->
      <div class="col-sm-12">
        <div class="p-4">
          <app-partido-miniatura [partido]="this.partido" [esconderVerMas]="true"></app-partido-miniatura>
        </div>
      </div>

      <!-- nomina local -->
      <div class="col-sm-12 col-md-6 p-2">
        <span class="teamNombre">{{this.partido.local.alias}}</span>
        <br>
        <div>
          <button style="float: right;" *ngIf="(this.estaAutorizado(AutorizacionPartidoEnum.Administrador) || this.estaAutorizado(AutorizacionPartidoEnum.CapitanLocal)) && !this.estaFinalizado()"
            class="btn btn-sm btn-warning" data-bs-toggle="modal" data-bs-target="#invitarLocalModal"
            (click)="this.actualizarInvitadosEquipoLocal()">Invitar</button>
          <span class="cantidadRespuestas colorAceptado"><i class="fa fa-check"></i> {{ this.contarPorEstado(this.nominaLocal, 1) }} </span>
          <span class="cantidadRespuestas colorPendiente"><i class="fa fa-question"></i> {{ this.contarPorEstado(this.nominaLocal, 0) }} </span>
          <span class="cantidadRespuestas colorRechazado"><i class="fa fa-times"></i> {{ this.contarPorEstado(this.nominaLocal, 2) }} </span>
        </div>
        <table class="table">
          <tbody>
            <tr *ngFor="let invitacion of this.nominaLocal"
              [ngClass]="{'text-bg-warning': this.jugadorIdSesion == invitacion.jugador.id && invitacion.respuesta == 0 }">
              <td style="width: 40px;">
                <app-posicion-miniatura [posicion]="invitacion.jugador.posicion"></app-posicion-miniatura>
              </td>
              <td>
                <a [routerLink]="['/jugador', invitacion.jugador.id]"> {{ invitacion.jugador.nombre }} {{
                  invitacion.jugador.apellido }}</a>
              </td>
              <td>
                <ng-container
                  *ngIf="invitacion.respuesta == 0 && this.jugadorIdSesion == invitacion.jugador.id && !this.estaFinalizado()">
                  <button class="btn btn-sm btn-success" (click)="aceptarInvitacion(invitacion)">Aceptar</button>
                  <button class="btn btn-sm btn-danger" (click)="rechazarInvitacion(invitacion)">Rechazar</button>
                </ng-container>
                <ng-container
                  *ngIf="!(invitacion.respuesta == 0 && this.jugadorIdSesion == invitacion.jugador.id && !this.estaFinalizado())">
                  <ng-container *ngIf="invitacion.respuesta == 0"><i class="fa fa-question colorPendiente"></i></ng-container>
                  <ng-container *ngIf="invitacion.respuesta == 1"><i class="fa fa-check colorAceptado"></i></ng-container>
                  <ng-container *ngIf="invitacion.respuesta == 2"><i class="fa fa-times colorRechazado"></i></ng-container>
                </ng-container>
              </td>
              <td *ngIf="(this.estaAutorizado(AutorizacionPartidoEnum.Administrador) || this.estaAutorizado(AutorizacionPartidoEnum.CapitanLocal)) && !this.estaFinalizado()">
                <button class="btn btn-sm btn-danger" data-bs-toggle="modal" data-bs-target="#removerInvitacionModal"
                  (click)="confirmarRemoverInvitacion(invitacion)"><i class="fa fa-trash"></i></button>
              </td>
            </tr>
            <tr *ngIf="this.nominaLocal.length < 1">
              <td colspan="3" class="border-0">Sin invitaciones</td>
            </tr>
          </tbody>
        </table>
        <div class="modal fade" id="invitarLocalModal" tabindex="-1" aria-labelledby="invitarLocalModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="invitarLocalModalLabel">Invitar</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">

                <!-- <div class="example-full-width" appearance="fill">
                  <label>Busca un jugador</label>
                  <input type="text" [(ngModel)]="this.filtroJugadorLocalTemporal" class="form-control"
                    placeholder="Email, Nombre, etc" />
                  <button class="btn btn-sm btn-primary" (click)="buscarJugadorLocal()"> <i class="fa fa-search"></i>
                    Buscar</button>
                </div> -->
                <ng-container *ngIf="!this.listaInvitadosLocalTemporal">
                  <div class="spinner-border text-success" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </ng-container>
                <ng-container *ngIf="this.listaInvitadosLocalTemporal">
                  <ng-container *ngIf="this.listaInvitadosLocalTemporal.length == 0">
                    <p>Ya los invitaste a todos</p>
                  </ng-container>
                  <ng-container *ngIf="this.listaInvitadosLocalTemporal.length > 0">
                    <p>Invita a un jugador de la nómina para que juegue este partido:</p>
                  </ng-container>
                  <table style="width: 100%;" class="divle cdk-table ng-star-inserted">
                    <tr *ngFor="let jugador of this.listaInvitadosLocalTemporal">
                      <td style="text-align: justify">
                        <a [routerLink]="['/jugador', jugador.id]"> {{ jugador.nombre }} {{ jugador.apellido
                          }}</a>
                      </td>
                      <td>
                        <app-posicion-miniatura [posicion]="jugador.posicion"></app-posicion-miniatura>
                      </td>
                      <td>
                        <button class="btn btn-sm btn-primary" (click)="invitarJugador(this.partido.local, jugador)"> <i
                            class="fa fa-plus"></i> Invitar</button>
                      </td>
                    </tr>
                  </table>
                </ng-container>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- nomina visita -->
      <div class="col-sm-12 col-md-6 p-2">
        <span class="teamNombre">{{this.partido.visita.alias}}</span>
        <div>
          <button style="float:right" 
            *ngIf="(this.estaAutorizado(AutorizacionPartidoEnum.Administrador) || this.estaAutorizado(AutorizacionPartidoEnum.CapitanVisita)) && !this.estaFinalizado()" class="btn btn-sm btn-warning" 
            data-bs-toggle="modal"
            data-bs-target="#invitarVisitaModal" (click)="this.actualizarInvitadosEquipoVisita()">Invitar</button>

          <span class="cantidadRespuestas colorAceptado"><i class="fa fa-check"></i> {{ this.contarPorEstado(this.nominaVisita, 1) }} </span>
          <span class="cantidadRespuestas colorPendiente"><i class="fa fa-question"></i> {{ this.contarPorEstado(this.nominaVisita, 0) }} </span>
          <span class="cantidadRespuestas colorRechazado"><i class="fa fa-times"></i> {{ this.contarPorEstado(this.nominaVisita, 2) }} </span>
        </div>
        <table class="table">
          <tbody>
            <tr *ngFor="let invitacion of this.nominaVisita"
              [ngClass]="{'text-bg-warning': this.jugadorIdSesion == invitacion.jugador.id }">
              <td style="width: 40px;">
                <app-posicion-miniatura [posicion]="invitacion.jugador.posicion"></app-posicion-miniatura>
              </td>
              <td style="text-align: justify;">
                <a [routerLink]="['/jugador', invitacion.jugador.id]"> {{ invitacion.jugador.nombre }} {{
                  invitacion.jugador.apellido }} </a>
              </td>
              <td>
                <ng-container
                  *ngIf="invitacion.respuesta == 0 && this.jugadorIdSesion == invitacion.jugador.id && !this.estaFinalizado()">
                  <button class="btn btn-sm btn-success" (click)="aceptarInvitacion(invitacion)">Aceptar</button>
                  <button class="btn btn-sm btn-danger" (click)="rechazarInvitacion(invitacion)">Rechazar</button>
                </ng-container>
                <ng-container>
                  <ng-container *ngIf="invitacion.respuesta == 0"><i class="fa fa-question colorPendiente"></i></ng-container>
                  <ng-container *ngIf="invitacion.respuesta == 1"><i class="fa fa-check colorAceptado"></i></ng-container>
                  <ng-container *ngIf="invitacion.respuesta == 2"><i class="fa fa-times colorRechazado"></i></ng-container>
                </ng-container>
              </td>
              <td *ngIf="(this.estaAutorizado(AutorizacionPartidoEnum.Administrador) || this.estaAutorizado(AutorizacionPartidoEnum.CapitanVisita)) && !this.estaFinalizado()">
                <button class="btn btn-sm btn-danger" data-bs-toggle="modal" data-bs-target="#removerInvitacionModal"
                  (click)="confirmarRemoverInvitacion(invitacion)"><i class="fa fa-trash"></i></button>
              </td>
            </tr>
            <tr *ngIf="this.nominaVisita.length < 1">
              <td colspan="3" class="border-0">Sin invitaciones</td>
            </tr>
          </tbody>
        </table>
        <div class="modal fade" id="invitarVisitaModal" tabindex="-1" aria-labelledby="invitarVisitaModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="invitarVisitaModalLabel">Invitar</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <!-- <div class="example-full-width" appearance="fill">
                  <label>Busca un jugador</label>
                  <input type="text" [(ngModel)]="this.filtroJugadorVisitaTemporal" class="form-control"
                    placeholder="Email, Nombre, etc" />
                  <button class="btn btn-sm btn-primary" (click)="buscarJugadorVisita()"> <i class="fa fa-search"></i>
                    Buscar</button>
                </div> -->
                <ng-container *ngIf="!this.listaInvitadosVisitaTemporal">
                  <div class="spinner-border text-success" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </ng-container>
                <ng-container *ngIf="this.listaInvitadosVisitaTemporal">
                  <ng-container *ngIf="this.listaInvitadosVisitaTemporal.length == 0">
                    <p>Ya los invitaste a todos</p>
                  </ng-container>
                  <ng-container *ngIf="this.listaInvitadosVisitaTemporal.length > 0">
                    <p>Invita a un jugador de la nómina para que juegue este partido:</p>
                    <table style="width: 100%;" class="divle cdk-table ng-star-inserted">
                      <tr *ngFor="let jugador of this.listaInvitadosVisitaTemporal">
                        <td style="text-align: justify">
                          <a [routerLink]="['/jugador', jugador.id]"> {{ jugador.nombre }} {{ jugador.apellido }}</a>
                        </td>
                        <td>
                          <app-posicion-miniatura [posicion]="jugador.posicion"></app-posicion-miniatura>
                        </td>
                        <td>
                          <button class="btn btn-sm btn-primary" (click)="invitarJugador(this.partido.visita, jugador)">
                            <i class="fa fa-plus"></i> Invitar</button>
                        </td>
                      </tr>
                    </table>
                  </ng-container>
                </ng-container>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="removerInvitacionModal" tabindex="-1" aria-labelledby="invitarLocalModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="invitarLocalModalLabel">Eliminar invitación</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <ng-container *ngIf="this.invitacionRemover">
                ¿Estas seguro que quieres eliminar la invitacion que le hiciste a {{
                this.invitacionRemover.jugador.nombre }}?
              </ng-container>
            </div>
            <div class="modal-footer">
              <button type="button" (click)="removerInvitacion()" class="btn btn-danger"
                data-bs-dismiss="modal">Eliminar</button>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>


      <!-- Relato -->
      <div class="col-sm-12 pt-4" >
        <h3 class="pt-4">Relato</h3>
        <div class="form-group pb-2 pt-2"
          *ngIf="(this.estaAutorizado(AutorizacionPartidoEnum.Administrador) || this.estaAutorizado(AutorizacionPartidoEnum.Arbitro) || this.estaAutorizado(AutorizacionPartidoEnum.Relator)) && !this.estaFinalizado()">
          <button class="btn btn-sm btn-primary"  data-bs-toggle="modal" data-bs-target="#agregarAccionModal">Agregar Accion</button>
        </div>

        <p *ngIf="this.partido.acciones.length == 0">Sin acciones reportadas</p>
        <table class="table table-sm" *ngIf="this.partido.acciones.length > 0">
          <thead>
            <tr>
              <th>Minuto</th>
              <th>Accion</th>
              <th>Jugador</th>
              <th *ngIf="(this.estaAutorizado(AutorizacionPartidoEnum.Administrador) || this.estaAutorizado(AutorizacionPartidoEnum.Arbitro) || this.estaAutorizado(AutorizacionPartidoEnum.Relator)) && !this.estaFinalizado()"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let accion of this.partido.acciones">
              <td>{{ accion.minuto }}</td>
              <td><app-accion-partido-miniatura [accion]="accion.accion"></app-accion-partido-miniatura></td>
              <td>{{ this.obtenerNombreJugador(accion.jugadorId) }}</td>
              <td *ngIf="(this.estaAutorizado(AutorizacionPartidoEnum.Administrador) || this.estaAutorizado(AutorizacionPartidoEnum.Arbitro) || this.estaAutorizado(AutorizacionPartidoEnum.Relator)) && !this.estaFinalizado()">
                <a *ngIf="eliminarConfirmacionRelatoId != accion.id" class="btn btn-sm btn-danger" (click)="this.eliminarConfirmacionRelatoId = accion.id"><i class="fa fa-trash"></i></a>
                <div *ngIf="eliminarConfirmacionRelatoId == accion.id">
                  Esta seguro?<br>
                  <button class="btn btn-danger btn-sm" (click)="this.eliminarAccion(accion)"><i class="fa fa-trash"></i> Si</button>
                  &nbsp;
                  <button class="btn btn-secondary btn-sm" (click)="this.eliminarConfirmacionRelatoId = ''"><i class="fa fa-undo"></i> No</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal fade" id="agregarAccionModal" tabindex="-1" aria-labelledby="agregarAccionModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="agregarAccionModalLabel">Agregar Acción</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label for="minuteText" class="form-label">Minuto</label>
                <input type="Minuto" class="form-control" id="minuteText" aria-describedby="minutoHelp" [(ngModel)]="this.accionTemporal.minuto">
                <div id="minutoHelp" class="form-text">El minuto en el que ocurrió la accion, ej: 34, 67, etc.</div>
              </div>
              <div class="mb-3">
                <label for="inputAccion" class="form-label">Acción</label>
                <select class="form-select" id="inputAccion" [(ngModel)]="this.accionTemporal.accion">
                  <option *ngFor="let opcion of this.enumeracionesService.obtenerAcciones()" [ngValue]="opcion.key">{{opcion.value}}</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="inputJugador" class="form-label">Jugador</label>
                <select [ngClass]="{'warning-border': this.accionTemporal.jugadorId == '' }" class="form-select" id="inputJugador" [(ngModel)]="this.accionTemporal.jugadorId">
                  <option *ngFor="let invitacion of this.nominaLocal" value="{{ invitacion.jugador.id }}">{{invitacion.jugador.nombre}} {{invitacion.jugador.apellido}} ({{ this.partido.local.alias }})</option>

                  <option *ngFor="let invitacion of this.nominaVisita" value="{{ invitacion.jugador.id }}">{{invitacion.jugador.nombre}} {{invitacion.jugador.apellido}} ({{ this.partido.visita.alias }})</option>
                </select>
                <small *ngIf="this.accionTemporal.jugadorId == ''" id="emailHelp" class="form-text text-muted">Seleccione un jugador para continuar.</small>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" (click)="agregarAccion()" [disabled]="this.accionTemporal.jugadorId == '' ? true : null" class="btn btn-success" data-bs-dismiss="modal">Agregar</button>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>


      <!--Comentarios-->
      <div class="col-sm-12 pt-4"
        [ngClass]="{'natural': this.partido.superficie === 1, 'sintetico' : this.partido.superficie === 2, 'carpeta' : this.partido.superficie === 3, 'parquet' : this.partido.superficie === 4, 'cemento' : this.partido.superficie === 5, 'tierra' : this.partido.superficie === 6, 'arena' : this.partido.superficie === 7 }">
        <h3 class="pt-4">Comentarios</h3>
        <figure class="pb-2 pt-2 bordeAbajo" *ngFor="let comentario of this.partido.comentarios">
          <blockquote class="blockquote">
            <p>{{ comentario.texto }}</p>
          </blockquote>
          <figcaption class="blockquote-footer">
            realizado por <cite title="Source Title">{{ comentario.jugador.nombre }}</cite> el <cite
              title="Source Title">{{ comentario.timestamp | date: 'MMM d, y h:mm a' }}</cite>
              <ng-container *ngIf="this.estaAutorizado(AutorizacionPartidoEnum.Administrador)">
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a class="btn btn-sm btn-secondary" *ngIf="this.eliminarConfirmacionComentarioId != comentario.id" href="javascript:void(0);" (click)="this.eliminarConfirmacionComentarioId = comentario.id;"><i class="fa fa-trash"></i> Eliminar</a>
                <ng-container *ngIf="this.eliminarConfirmacionComentarioId == comentario.id">
                  <br>
                  ¿Esta seguro?
                  <a class="btn btn-sm btn-danger" (click)="this.eliminarComentario(comentario);"><i class="fa fa-trash"></i> Si</a>&nbsp;
                  <a class="btn btn-sm btn-secondary" (click)="this.eliminarConfirmacionComentarioId = ''"><i class="fa fa-times"></i> No</a>
                </ng-container>
              </ng-container>
          </figcaption>
        </figure>
        <p *ngIf="this.partido.comentarios.length < 1">Sin comentarios</p>

        <div class="form-group pb-2 pt-2"
          *ngIf="(this.estaAutorizado(AutorizacionPartidoEnum.Administrador) || this.estaAutorizado(AutorizacionPartidoEnum.CapitanLocal) || this.estaAutorizado(AutorizacionPartidoEnum.CapitanVisita)) && !this.estaFinalizado()">
          <textarea class="form-control" [(ngModel)]="this.comentarioTemporal"></textarea>
          <button class="btn btn-sm btn-primary" (click)="this.comentar()" [disabled]="this.comentarioTemporal.length < 1 ? true : null">Comentar</button>
        </div>
      </div>


      <!--Estadisticas-->
      <div *ngIf="this.estaFinalizado() && this.partido.resultado && this.estadisticasPartido" class="col-sm-12 pt-4">
        <h3 class="pt-4" style="text-align: center;">Estadísticas del partido</h3>
        <table class="table cdk-table mat-elevation-z8 ng-star-inserted stats" width="100%"
          *ngIf="this.estadisticasPartido">
          <tr>
            <td class="score" width="30%">
              <strong>{{ this.partido.resultado.local }}</strong>
            </td>
            <td width="40%">Gol</td>
            <td class="score" width="30%">
              <strong>{{ this.partido.resultado.visita }}</strong>
            </td>
          </tr>
          <tr>
            <td><strong>{{ this.partido.local.alias }}</strong></td>
            <td></td>
            <td><strong>{{ this.partido.visita.alias }}</strong></td>
          </tr>
          <tr>
            <td>{{ this.estadisticasPartido.pasesLocal }}</td>
            <td>Pases</td>
            <td>{{ this.estadisticasPartido.pasesVisita }}</td>
          </tr>
          <tr>
            <td>{{ this.estadisticasPartido.tirosPorteriaLocal }}</td>
            <td>Tiros</td>
            <td>{{ this.estadisticasPartido.tirosPorteriaVisita }}</td>
          </tr>
          <tr>
            <td>{{ this.estadisticasPartido.perdidasLocal }}</td>
            <td>Perdidas</td>
            <td>{{ this.estadisticasPartido.perdidasVisita }}</td>
          </tr>
          <tr>
            <td>{{ this.estadisticasPartido.quitesLocal }}</td>
            <td>Quites</td>
            <td>{{ this.estadisticasPartido.quitesVisita }}</td>
          </tr>
        </table>
      </div>
    </div>

    <!-- terminar partido modal -->
    <div *ngIf="this.partido && !this.estaFinalizado()" class="modal fade" id="terminarPartidoModal" tabindex="-1"
      aria-labelledby="terminarPartidoModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="terminarPartidoModalLabel">Finalizar Partido</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Ingresa el marcador final de este partido:</p>

            <table style="width: 100%; border: 0px">
              <tr>
                <td style="width: 40%; text-align: center; border: 0px">
                  <input type="text" [(ngModel)]="this.partido.resultado.local" class="form-control"
                    style="text-align: center;" /><br>
                  <span>{{ partido.local.alias }}</span>
                </td>
                <td style="width: 20%; text-align: center; border: 0px">
                  -
                </td>
                <td style="width: 40%; text-align: center; border: 0px">
                  <input type="text" [(ngModel)]="this.partido.resultado.visita" class="form-control"
                    style="text-align: center;" /><br>
                  <span>{{ partido.visita.alias }}</span>
                </td>
              </tr>
            </table>


          </div>
          <div class="modal-footer">
            <button type="button" (click)="finalizarPartido()" class="btn btn-primary"
              data-bs-dismiss="modal">Finalizar</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>

    <!-- eliminar partido modal -->
    <div *ngIf="this.partido" class="modal fade" id="eliminarPartidoModal" tabindex="-1"
      aria-labelledby="eliminarPartidoModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="eliminarPartidoModalLabel">Eliminar Partido</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>¿Estas seguro que deseas eliminar este partido?. Esta operacion es irreversible.</p>
          </div>
          <div class="modal-footer">
            <button type="button" (click)="eliminarPartido()" class="btn btn-danger"
              data-bs-dismiss="modal">Eliminar</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>

    <!-- container loading -->
    <ng-container *ngIf="!this.partido && !this.noEncontrado">
      <div class="px-4 py-5" id="custom-cards">
        <div class="spinner-border text-success" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </ng-container>

    <!-- partido no encontrado -->
    <ng-container *ngIf="!this.partido && this.noEncontrado">
      <div class="px-4 py-5" id="custom-cards">
        <p>El partido no fue encontrado.</p>
      </div>
    </ng-container>
  </main>
</div>